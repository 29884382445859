// https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/
// Respond above.
@mixin respond-above($grid-breakpoint) {

  // If the grid-breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $grid-breakpoint) {
    // Get the grid-breakpoint value.
    $grid-breakpoint-value: map-get($grid-breakpoints, $grid-breakpoint);

    // Write the media query.
    @media (min-width: $grid-breakpoint-value) {
      @content;
    }

    // If the grid-breakpoint doesn't exist in the map.
  }

  @else {
    // Log a warning.
    @warn "Invalid grid-breakpoint: #{$grid-breakpoint}.";
  }
}

// Usage:
//   @include respond-above(sm) {
//     .element {
//       font-weight: bold;
//     }
//   }
// Output:
// @media (min-width: 768px) {
//     .element {
//       font-weight: bold;
//     }
// }

@mixin respond-below($grid-breakpoint) {

  // If the grid-breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $grid-breakpoint) {
    // Get the grid-breakpoint value.
    $grid-breakpoint-value: map-get($grid-breakpoints, $grid-breakpoint);

    // Write the media query.
    @media (max-width: ($grid-breakpoint-value - 1)) {
      @content;
    }

    // If the grid-breakpoint doesn't exist in the map.
  }

  @else {
    // Log a warning.
    @warn "Invalid grid-breakpoint: #{$grid-breakpoint}.";
  }
}

@mixin respond-between($lower, $upper) {

  // If both the lower and upper grid-breakpoints exist in the map.
  @if map-has-key($grid-breakpoints, $lower) and map-has-key($grid-breakpoints, $upper) {
    // Get the lower and upper grid-breakpoints.
    $lower-grid-breakpoint: map-get($grid-breakpoints, $lower);
    $upper-grid-breakpoint: map-get($grid-breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-grid-breakpoint) and (max-width: ($upper-grid-breakpoint - 1)) {
      @content;
    }

    // If one or both of the grid-breakpoints don't exist.
  }

  @else {

    // If lower grid-breakpoint is invalid.
    @if map-has-key($grid-breakpoints, $lower)==false {
      // Log a warning.
      @warn "Your lower grid-breakpoint was invalid: #{$lower}.";
    }

    // If upper grid-breakpoint is invalid.
    @if map-has-key($grid-breakpoints, $upper)==false {
      // Log a warning.
      @warn "Your upper grid-breakpoint was invalid: #{$upper}.";
    }
  }
}
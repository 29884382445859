.index {
  &__content {
    --col: 25vmax;

    @include grid;
    @include gridAuto;

    height: 100%;
    place-items: center;

    // Übersichtseite der Artikel
    article {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      transition: all $transition-time cubic-bezier(0.165, 0.84, 0.44, 1);
      will-change: transform, opacity;

      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: op.shadow(3);
        opacity: 0;
        transition: all $transition-time cubic-bezier(0.165, 0.84, 0.44, 1);
      }

      &:hover {
        z-index: 1;

        &::after {
          opacity: 1;
        }
      }

      h2 {
        text-align: center;
        margin-bottom: 0;

        a {
          text-decoration: none;
          @extend .stretched-link;
        }
      }
    }
  }
}
// @font-face {
//   font-family: Quicksand;
//   src: url("../font/") format('woff2-variations');
//   font-optical-sizing: auto;
//   font-display: swap;
// }

@include Quicksand.faces($subsets: (latin-ext),
  $weights: (400,
    500,
    600,
    700,
  ),
  $styles: all,
  $directory: "../font/",
);

$modern-fonts-stack: avenir, "Avenir Next LT Pro", montserrat, corbel, "URW Gothic", source-sans-pro, $font-family-base; // https://modernfontstacks.com/

body {
  hyphens: auto;
  font-feature-settings: "liga", "clig", "kern";
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: Quicksand, $modern-fonts-stack;
  font-variation-settings: "wght" 400;

  // &[data-theme='dark'] {
  //     font-variation-settings: 'wght' 300;
  // }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-variation-settings: "wght" 500;
  hyphens: auto;

  &[data-theme="dark"] {
    font-variation-settings: "wght" 400;
  }
}

strong,
b {
  font-variation-settings: "wght" 600;
}

a[href^="mailto:"],
li a {
  hyphens: none;
}

h1 {
  font-size: clamp(2rem, 9vw, 2.2rem);
  margin-block-end: 1rem;
}

h2 {
  font-size: clamp(1.5rem, 6vw, 1.8rem);
  margin-block-end: 0.75rem;
}

h3 {
  font-size: clamp(1.3rem, 5vw, 1.5rem);
  margin-block-end: 0.5rem;
}

p {
  font-size: #{op.$font-size-fluid-0};
  margin-block-end: 0.5rem;
}
/* Navbar */
.navbar {
  &-custom {
    @extend .navbar-light;
  }

  .nav-link {
    color: var(--link);
  }
}

[color-scheme="default"] {
  .navbar-custom {
    @extend .navbar-light;
  }
}

[color-scheme="dark"] {
  .navbar-custom {
    @extend .navbar-dark;
  }
}

.site__name {
  margin-bottom: 0;

  a {
    display: block;
    text-indent: -400%;
    background: transparent url("../img/acat-logo.svg") 0 0 no-repeat;
    background-size: contain;
    overflow: hidden;
  }
}
@charset "UTF-8";

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 0.5rem;
$hamburger-padding-y: 0;
$hamburger-layer-width: 2.5rem !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-spacing: 7px !default;
$hamburger-layer-color: currentcolor;
$hamburger-layer-border-radius: $border-radius !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  elastic) !default;

// Base Hamburger (We need this)
// ==================================================
@import "../../../../node_modules/hamburgers/_sass/hamburgers/base";

// Hamburger types
// ==================================================
@import "../../../../node_modules/hamburgers/_sass/hamburgers/types/elastic";

// ==================================================
// Bootstrap navbar expand

.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints
    );
  $infix: breakpoint-infix($next, $grid-breakpoints);

  // stylelint-disable-next-line scss/selector-no-union-class-name
  &#{$infix} {
    @include media-breakpoint-up($next) {
      .hamburger {
        display: none;
      }
    }
  }
}
}
// Feeds
.feed {
  &__index {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gap) * 2);
  }
}

time {
  color: var(--foreground-lower);
}
// layout
.container {
  @include grid;

  grid-template-rows: auto 1fr auto;
  height: 100%;
}

.navbar {
  margin-top: var(--gap);
}

@include respond-below(md) {
  .navbar-nav {
    margin-top: var(--gap);
  }
}

.site {
  &__header {
    h1 {
      display: inline-block;
    }
  }

  &__content {
    // <main>
    display: flex;
    flex-direction: column;
  }

  &__header,
  &__content,
  &__footer {
    align-content: start;
  }

  &__content>article {
    display: grid;
    gap: calc(var(--gap) * 0.5);
  }
}
html {
  color-scheme: light dark;
}

html,
body {
  height: 100%;
}

body {
  color: var(--foreground);
  background: var(--background);
  transition: all 300ms;
}

a {
  color: var(--link);

  &:hover {
    color: var(--link-higher);
  }
}

.categories {
  @include list-unstyled;

  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: $list-inline-padding;
    }

    a {
      @extend .text-muted;
    }
  }
}

// blocks
.block {

  &-paragraph_block,
  &-markdown_block {
    margin-bottom: var(--gap);

    @include media-breakpoint-up(md) {
      ul {
        @include grid;
        @include gridAuto;

        gap: var(--gap) calc(var(--gap) * 2) !important;

        li {
          break-inside: avoid;
        }
      }
    }
  }

  &-image_block {
    margin-bottom: calc(var(--gap) * 2);
  }
}

// home
.home {
  .site__content {
    display: grid;
    gap: 0.5rem;
    align-content: center;

    h1 {
      hyphens: none;
    }

    .block-card_block {
      margin: calc(var(--gap) * 2) 0;

      div {
        @include grid;
        @include gridAuto;

        --col: 20rem;

        @include media-breakpoint-up(lg) {
          justify-items: center;
        }
      }

      header {
        display: flex;
        margin-bottom: 1rem;

        h2 {
          margin-bottom: 0;

          a {
            text-decoration: none;
          }
        }

        svg {
          min-height: 2rem;
          min-width: 2rem;
          align-self: center;
          vertical-align: middle;
          margin-right: 1rem;
        }
      }

      h3 {
        margin-left: 3.2rem;
      }
    }
  }
}

::target-text {
  background-color: color-mix(in srgb, var(--link) 25%, transparent);
  color: var(--foreground);
}
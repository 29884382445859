// colors

:root {
  --gap: 1rem;
  --col: 15rem;

  --background: #{op.$gray-0};
  --foreground: #{op.$gray-8};
  --middleground: #{op.$gray-6};
  --background-lower: #{op.$gray-1};
  --background-higher: #{op.$gray-0};
  --foreground-higher: #{op.$gray-9};
  --foreground-lower: #{op.$gray-7};
  --shadow-color: #{op.$gray-5};
  --accent: #{op.$red-8};
  --link: #{op.$red-9};
  --link-higher: #{op.$red-10};

  // 25.03.25 Dunkles Thema vorübergehend auskommentiert
  // --background: light-dark(#{op.$gray-0}, #{op.$gray-9});
  // --foreground: light-dark(#{op.$gray-9}, #{op.$gray-0});
  // --foreground-higher: light-dark(#{op.$gray-10}, #fff);
  // --foreground-lower: light-dark(#{op.$gray-7}, #{op.$gray-3});
  // --background-higher: light-dark(#fff, #{op.$gray-2});
  // --accent: light-dark(#{op.$red-8}, #{op.$red-2});
  // --link: light-dark(#{op.$red-10}, #{op.$red-3});
  // --link-higher: light-dark(#{op.$red-9}, #{op.$red-3});
  // --middleground: #{op.$gray-6};

  --border-color: currentcolor;
  --bs-pagination-bg: var(--background);
  --bs-pagination-color: var(--foreground);
  --bs-pagination-disabled-bg: var(--background);
  --bs-pagination-disabled-color: var(--foreground-lower);

  // color-scheme
  // https://developer.mozilla.org/en-US/docs/Web/CSS/color-scheme
  // color-scheme: light dark;
  color-scheme: light;
}

$primary: rgb(163 24 24);
$breadcrumb-active-color: var(--middleground);
$breadcrumb-divider-color: var(--border-color);
$carousel-control-color: $primary;
$card-bg: var(--theme-bg-color);
$card-header-bg: var(--theme-bg-color);
$transition-time: 0.3s;

$pagination-bg: var(--background);
$pagination-color: var(--foreground);
$pagination-disabled-bg: var(--background);
$pagination-disabled-color: var(--foreground-lower);

// containers
$container-max-widths: (
  sm: 540px,
  md: 620px,
  lg: 760px,
  xl: 840px,
  xxl: 1020px,
);

// @supports not (color: light-dark(white, black)) {
//   :root {
//     --link: #{op.$red-9};
//     --link-higher: #{op.$red-10};
//     --background: #{op.$gray-0};
//     --foreground: #{op.$gray-8};
//     --middleground: #{op.$gray-6};
//     --background-lower: #{op.$gray-1};
//     --background-higher: #{op.$gray-0};
//     --foreground-higher: #{op.$gray-9};
//     --foreground-lower: #{op.$gray-7};
//     --shadow-color: #{op.$gray-5};
//   }
// }
/* Footer */
.site__footer {
  @include grid;
  grid-template-columns: min-content repeat(2, auto);
  gap: var(--gap);
  font-size: 90%;
  border-top: 1px solid var(--border-color);

  padding-block: var(--gap);
  justify-content: space-between;
  align-items: center;

  [role="note"] {
    @include grid;
    grid-template-columns: repeat(2, auto);
    gap: var(--gap);
    align-items: center;

    p,
    div {
      margin-bottom: 0;
    }
  }

  button {
    border: 0;
    background-color: unset;
  }
}
// Bilder
img {
  @include img-fluid;
}

figure,
picture {
  img {
    box-shadow: 0 0 0.3rem 0 rgb(41 41 41 / 56.8%);

    @media (prefers-color-scheme: dark) {
      filter: brightness(0.9) contrast(1.1);

      &:hover {
        filter: none;
      }
    }
  }

  figcaption {
    font-size: 90%;
    color: $gray-600;
  }
}

// slider
.carousel {
  &-inner {
    overflow: unset;
  }

  figure {
    img {
      margin: 0 auto;
      box-shadow: 0 0 10px 0 rgb(68 68 68 / 60%);
    }

    figcaption {
      background-color: rgba(#534e4e, 0.5);

      p {
        font-variation-settings: "wght" 300;
      }
    }
  }
}
/*!
* Acat GmbH acat.cc website 2020-2025
* Krzysztof Jeziorny <kj@acat.cc>, Acat GmbH
*/

@use '../../../node_modules/open-props-scss' as op;
@use "../../../node_modules/@fontsource-variable/quicksand/scss/mixins" as Quicksand;

@import "variables";
@import "mixins";

// Bootstrap 5
@import "bootstrap";

// Acat
@import "grid";
@import "components/layout";
@import "components/hamburger";
@import "components/navigation";
@import "components/footer";
@import "components/index";
@import "components/typography";
@import "components/image";
@import "components/icon";
@import "components/feed";
@import "components/a11y";
// @import "components/view-transitions";
@import "general";